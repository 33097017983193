import React from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';

interface NewCollectionsProps {
  data: any;
}

// interface CollectionItemProps {
//     name: string;
//     imageUrl: string;
//     description: string;
//     link: string;
// }

const NewCollections: React.FC<NewCollectionsProps> = ({ data }) => {
  // const CollectionItem: React.FC<CollectionItemProps> = ({ name, imageUrl, description, link }) => (
  //     <a href={link} className="slick-slide slick-active" style={{ width: '445px' }}>
  //         <figure>
  //             <img className="lazy loaded" src={imageUrl} alt={`Collection: ${name}`} title={name} />
  //         </figure>
  //         <p className="collection-name">
  //             {name} <span className="name">{description}</span>
  //         </p>
  //     </a>
  // );

  return (
    <>
      <section className={styles.collectionssection}>
        <div className="container-fluid">
          <div className={styles.collectionsinner}>
            <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={styles.collectionsheading}>
                  <h2>{data?.title}</h2>
                  <p>{data?.subtitle}</p>
                </div>
              </div>
            </div>
            <div className={styles.collectionscontent}>
              <div className="collectionsslider">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={12}
                  navigation={true}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 12,
                    },
                    1300: {
                      slidesPerView: 4,
                      spaceBetween: 12,
                    },
                  }}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  {data?.items?.map((collection: any, index: number) => (
                    <SwiperSlide key={index}>
                      <Link
                        href={collection.slug}
                        className={styles.collectionscard}
                      >
                        <div className={styles.collectionsimg}>
                          <figure>
                            <Image
                              src={collection.image}
                              alt={collection.alt ? collection.alt : ''}
                              width={500}
                              height={500}
                              loading="lazy"
                              quality={100}
                            />
                          </figure>
                        </div>
                        <div className={styles.collectionstext}>
                          <h4>{collection.name}</h4>
                          <p>{collection.description}</p>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewCollections;
